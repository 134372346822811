<template>
    <b-container>
        <b-row>
            <b-col cols="12" md="5" order="1" order-md="0">
                <u-img-blockquote class="mt-md-5"
                                  col-classes="col-12 col-md-12"
                                  img-src="/img/main-banner-form.png"
                                  img-alt="Уже есть бизнес? Откройте счет прямо сейчас!"
                                  img-blockquote="Наведите камеру на QR код"
                />
            </b-col>
            <b-col cols="12" md="6" offset-md="1" order="0" order-md="1">
                <h2 class="font-weight-bold text-center text-lg-left mb-1 mb-md-4 pb-md-2">
                    Уже есть бизнес?<br>
                    Откройте счет прямо сейчас!
                </h2>
                <div class="text text-center text-lg-left mb-4 mb-md-5 pb-md-2">
                    Оставьте заявку на открытие счёта, и специалист банка «ВТБ» свяжется с Вами в самое ближайшее время
                </div>
                <basic-form form-class="form-label" :form="form" :submit-button="submitButton"/>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {Form} from "../../classes/form";
    import {InputGroup} from "../../classes/form/inputGroup";
    import {Input, Checkbox, InputFloatLabel} from "../../classes/form/input";
    import {required, minLength, maxLength} from 'vuelidate/lib/validators'

    export default {
        name: "BannerWithForm",
        data() {
            return {
                submitButton: {
                    variant: 'u-button',
                    text: 'Оставить заявку',
                    size: 'medium',
                    classButton: 'd-block mr-auto ml-auto ml-lg-0 mb-4 mb-md-0',
                    type: 'submit',
                },
            }
        },
        components: {
            UImgBlockquote: () => import(/* webpackChunkName: 'u-img-blockquote'*/'../UImgBlockquote'),
            BasicForm: () => import(/* webpackChunkName: 'basic-form-in-header'*/'../BasicForm/'),
        },
        computed: {
            form() {
                return new Form(
                    'Уже есть бизнес? Откройте счет прямо сейчас!',
                    [
                        (new InputGroup(
                                (new Input(
                                    'tn',
                                    '',
                                    '6',
                                    'd-none',
                                    '',
                                    '',
                                    '',
                                )).setValidatorOptions({
                                    required
                                }),
                                (new Input(
                                    'type',
                                    '',
                                    'Уже есть бизнес? Откройте счет прямо сейчас!',
                                    'd-none',
                                    '',
                                    '',
                                    '',
                                )).setValidatorOptions({
                                    required
                                }),
                                (new InputFloatLabel('application_applicant', 'Наименование заявителя', '', 'col-md-12',)
                                ).setValidatorOptions(
                                    {
                                        required,

                                    }
                                ),
                                (new InputFloatLabel('application_contact_person', 'Контактное лицо', '', 'col-md-12',)
                                ).setValidatorOptions(
                                    {
                                        required,

                                    }
                                ),
                                (new InputFloatLabel('application_contact_phone', 'Телефон', '', 'col-md-12', 'Просьба указать телефон с кодом города. В формате: (код города) номер телефона','', '+375#########')
                                ).setValidatorOptions(
                                    {
                                        required
                                        // alpha: val => /^\+?[0-9]+$/.test(val),
                                        // minLength: minLength(13),
                                        // maxLength: maxLength(16)
                                    }
                                ),
                                (new Checkbox('application_agree',
                                        'Оставляя заявку, вы принимаете условия <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/soglasiye-na-personalnyye-dannyye.pdf">Пользовательского соглашения и Политики конфиденциальности</a>.',
                                        'application_agree', 'col-md-12')
                                ).setValidatorOptions(
                                    {required}
                                ),
                            )
                        ),
                    ],
                );
            },
        },
    }
</script>
